<template>
 <div class="flex flex-wrap p-2" v-if="currentUser.type == 'Admin' || currentUser.type == 'CEO'">
    <div class="w-full">
        <chart-revenus
          :chartData="dataExpFees"
          :chartData1="dataExp"
          :Labels="labelsExp"
          :label="labelExp"
          :title="$t('expeditions_analytic')"
          type="line"
          id="expeditions"
          :sellers="sellers"
          :userType="currentUser.type"
          :timeserch="timeserchExp"
          :timeserchseller="timeserchsellerExp"
          action="expeditions"
          :labelstring="labelStringExp"
          @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
          @ChangeSeller="ChangeSellerExp"
        />
    </div>
 </div>

</template>

<script>
import ChartRevenus from "@/components/Analytics/Cards/ChartRevenu.vue";
export default {
  name: "dashboard-page",
  components: {
    ChartRevenus,
  },
  props: {
     currentUser: { type: Object },
  },
  data() {
    return {
        dataExp: [],
          sellers:[],
        dataExpFees: [],
        labelsExp: [],
        labelExp: "Transport fees",
        timeserchExp: "today",
        timeserchsellerExp: "All",
        labelStringExp: "Hours",
         timeserchseller: "All",
    }
  },
  computed: {
    warhouseSelected(){
      return this.$store.getters["wharhouse/warhouseSelected"];
    },
  },
   watch: {
      'warhouseSelected': async function(oldVal, newVal) {
        await this.getSellers();
        await this.getRevenus_and_TopProducts_Stock({
          DateType: this.timeserchExp,
          action: "expeditions",
      });
      }
   } ,   
  async mounted() {
     await this.getSellers();
    await this.getRevenus_and_TopProducts_Stock({
        DateType: this.timeserchExp,
        action: "expeditions",
        });
  },
  methods:{
     async getSellers() {
      const res = await this.$server.search("users", { type: "Seller",countries:await this.warhouseSelected, limit:10 });
      if (res.content.results && (this.currentUser.type == 'Admin'|| this.currentUser.type == 'CEO')) {
        this.sellers = res.content.results;
        this.sellers.unshift("All");
      }
    },
    async ChangeTypeDateRevenu(event) {
     if (event.target.name == "expeditions") {
        this.timeserchExp = event.target.value;
        this.labelsExp = [];
        this.dataExp = [];
        this.dataExpFees = [];
        if (event.target.value == "today") {
          this.labelStringExp = "Hours";
        } else if (event.target.value == "thisyear") {
          this.labelStringExp = "Months";
        } else {
          this.labelStringExp = "Days";
        }
      }
   
      if( event.target.value != "betweendate"){
        await this.getRevenus_and_TopProducts_Stock({
          DateType: event.target.value,
          action: event.target.name,
        });
      }
    },
    async ChangeSellerExp(seller) {
      this.timeserchsellerExp = seller._id;
      this.dataExp = [];
      this.dataExpFees = [];
      this.labelsExp = [];
      await this.getRevenus_and_TopProducts_Stock({
        DateType: this.timeserchExp,
        action: "expeditions",
      });
    },
    getTymeTypeExp() {
      var type = "";
      if (this.timeserchExp == "today") {
        type = "hour";
      } else if (this.timeserchExp == "thisyear") {
        type = "month";
      } else {
        type = "day";
      }
      return type;
    },
    async getRevenus_and_TopProducts_Stock(filters) {
        filters.Seller=this.timeserchsellerExp;
         const res = await this.$server.getRevenus_and_TopProducts_Stock("analytics",filters);
        if (filters.action == "expeditions"){
          res.content.sort(
            (a, b) =>
              a._id[this.getTymeTypeExp()] - b._id[this.getTymeTypeExp()]
          );
        }
        if (res.content) {
            for (const element of res.content) {
                if (filters.action == "expeditions") {
                this.dataExpFees.push(element.transport_fees);
                this.dataExp.push(element.count);
                if (this.timeserch == "today") {
                    this.labelsExp.push(("0" + element._id.hour).slice(-2));
                } else if (this.timeserch == "thisyear") {
                    this.labelsExp.push(this.GetMonthName(element._id.month));
                } else {
                    this.labelsExp.push(("0" + element._id.day).slice(-2));
                }
                } 
            }
        }else{
           if (filters.action == "expeditions") {
                this.dataExp = [];
                this.dataExpFees = [];
                this.labelsExp = [];
           }
        }
    }
  }
}
</script>